/* global ga */

function event (category, action, label) {
  const event = {}
  if (category) event.eventCategory = category
  if (action) event.eventAction = action
  if (label) event.eventLabel = label

  ga('send', 'event', event)
}

function page (page) {
  ga('send', {
    'hitType': 'pageview',
    'page': page
  })
}

export default {
  event,
  page
}
