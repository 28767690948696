import anime from 'animejs'
import ga from 'tools/ga'

const $dom = document.getElementById('loader');
const $svg = $dom.getElementsByTagName('svg')[0]
const $body = document.getElementsByTagName('body')[0];

function show () {
  return new Promise((resolve) => {
    mouseLoading(true);

    anime({
      targets: $svg,
      duration: 350,
      delay: 250,
      opacity: [0, 1],
      easing: 'linear'
    })

    anime({
      targets: $dom,
      duration: 500,
      height: ['0%', '100%'],
      easing: 'easeOutQuad',
      complete: resolve
    })
  });
}

function mouseLoading (bool) {
  bool ? $body.classList.add('progress') : $body.classList.remove('progress');
}

function hide () {
  return new Promise((resolve) => {
    anime({
      targets: $svg,
      duration: 350,
      opacity: [1, 0],
      easing: 'linear'
    })

    anime({
      targets: $dom,
      duration: 500,
      delay: 250,
      height: ['100%', '0%'],
      easing: 'easeOutQuad',
      complete: () => {
        ga.page('/NES/Loader/')
        resolve()
        mouseLoading(false)
      }
    })
  });
}

export default {
  show,
  hide
}
