import bowser from 'bowser'

const $html = document.getElementsByTagName('html')[0];

let mobile;
let tablet;
let device;
let desktop;
let ie;

if ($html.classList.contains('ie') || $html.classList.contains('ie11')) {
  ie = true;
}

if ($html.classList.contains('mobile')) {
  mobile = true;
  device = true;
} else if ($html.classList.contains('tablet')) {
  tablet = true;
  device = true;
} else if ($html.classList.contains('desktop')) {
  desktop = true;
}

const config = {
  mobile,
  tablet,
  desktop,
  device,
  ie,
  edge: $html.classList.contains('edge'),
  safari: $html.classList.contains('safari'),
  safari9: $html.classList.contains('safari9'),
  firefox: $html.classList.contains('firefox'),
  ios: $html.classList.contains('ios'),
  files: [],
  locales: window.config.locales,
  locale: window.config.locale || 'fr',
  path: window.config.path,
  isDev: window.config.isDev,
  baseDir: window.location.origin + (window.config.path ? window.config.path : ''),
  webRTC: true,
  webm: false,
  oldbrowser: bowser.isUnsupportedBrowser({
    msie: '10',
    firefox: '48',
    chrome: '55',
    safari: '9'
  })
}

navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia
if (config.ios && config.safari && !navigator.getUserMedia) config.webRTC = false

var tmpVideo = document.createElement('video')
if (tmpVideo.canPlayType) config.webm = tmpVideo.canPlayType('video/webm; codecs="vp8, vorbis"') !== ''

let canvas = document.createElement('canvas')
let gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl') || canvas.getContext('webgl-experimental');
config.webGL = gl && gl instanceof window.WebGLRenderingContext

export default config;
